import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { setPageTabAction, SET_LANGUAGE } from '../SearchResults/global/searchResultGlobalMiscActions'
import { TRootState } from '../SearchResults/searchResultsRootReducers'
import searchResultsStore from '../SearchResults/searchResultsStore'
import { EPageTab } from '../SearchResults/types/EPageTag'
import { TNavigationMenuItem, menuList } from './menuList'
import { DivNavigation } from './Navigation.css'
import translations from './translations'

/**
 * This component switches the current selected page tab
 */
function Navigation(props) {
    const { language, hiddenRoutes = [], pageTab } = props

    const router = useRouter()
    const menuListRef = useRef<HTMLDivElement>(null)
    const activeMenuRef = useRef<HTMLDivElement>(null)
    const currentPageTab: EPageTab = useSelector<TRootState, EPageTab>((state) => state.globalMisc.pageTab)
    const filteredMenuList = menuList.filter((menuItem: TNavigationMenuItem) => {
        return hiddenRoutes.indexOf(menuItem.route) === -1
    })

    const t = (text: string): string => {
        if (language === 'en') {
            return text
        } else {
            if (translations[text]) {
                return translations[text]
            } else {
                return text
            }
        }
    }

    /**
     * This `Navigation` component is treated as a `sort` component,
     * it would change the current index, then it searches
     * @param pageTab
     */
    const handleNavigationLinkClick = (pageTab: EPageTab) => {
        // Inform globally which pageTab is currently active
        searchResultsStore.dispatch(setPageTabAction(pageTab))
        searchResultsStore.dispatch({
            type: SET_LANGUAGE,
            payload: language
        })
        if (typeof window !== 'undefined') {
            // `history=historyReplaceState` to inform that this URL should be handled using History.replaceState()
            // So we would ignore this URL in the History Object, otherwise whenever we click on the `back` button
            // of the browser, it would go back to this link
            // More info: https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
            window.localStorage.setItem('historyReplaceState', 'true')
            sessionStorage.removeItem('GlobalParams')
        }
    }

    // Scroll the menu item to the left, to prevent the active items
    // and the other items to the right, from hidden
    useEffect(() => {
        const el = menuListRef.current
        if (el) {
            const windowWidth = window?.innerWidth || 0
            const activeOffset = activeMenuRef.current?.offsetLeft || 0

            el.scrollLeft = activeOffset > windowWidth / 2 ? activeOffset : 0
        }
    }, [currentPageTab])

    return (
        <DivNavigation ref={menuListRef}>
            {filteredMenuList.map((item: TNavigationMenuItem, index: number) => {
                const currentTab = currentPageTab !== EPageTab.UNSET ? currentPageTab : pageTab

                // fix quick
                const isActive =
                    router.pathname === '/'
                        ? currentTab === item.pageTab // for normal jobs/management jobs page
                        : router.pathname === item.route

                const activeLink = (
                    <span key={index} className='nav-item active' title={t(item.name)} ref={activeMenuRef}>
                        <span className='nav-icon'>{isActive ? item.activeIcon : item.icon}</span>
                        <span className='nav-label'>{t(item.name)}</span>
                    </span>
                )

                const link = (
                    <Link href={t(item.route)} key={index} as={t(item.routeAlias)} shallow prefetch={false}>
                        <a
                            className='nav-item'
                            title={t(item.name)}
                            onClick={() => handleNavigationLinkClick(item.pageTab)}
                        >
                            <span className='nav-icon'>{isActive ? item.activeIcon : item.icon}</span>
                            <span className='nav-label'>{t(item.name)}</span>
                        </a>
                    </Link>
                )

                return isActive ? activeLink : link
            })}
        </DivNavigation>
    )
}

export default Navigation
