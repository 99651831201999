import React from 'react'
import { ELanguage } from '../SearchResults/utils/language'
import withComponentTranslation from '../SearchResults/utils/translatorHOC'
import { handleJobItem } from './jobItemHelper'
import vi from './vi.json'

export type TJobItem = {
    locations: any
    postedDate: number
    salaryMin: string
    salaryMax: string
    alias: string
    jobId: string
    language: ELanguage
    companyLogo: string
    jobTitle: string
    company: string
    companyId: number
    translate: (term: string) => string
    isUserLoggedIn: boolean
    userId: number
    isSalaryVisible: boolean
    isAnonymous: boolean
}

const JobItem = (props: TJobItem) => {
    const { linkJob, locations, companyUrl } = handleJobItem(props)

    return (
        <div className='job-item row'>
            <div className='col-xs-3 col-sm-3 col-lg-2 job-item__col-noresult'>
                <div className='job-item__img-noresult'>
                    <a href={linkJob} className='pio-item-link' data-item-id='1197717' target='_self'>
                        <img className='img-responsive' src={props.companyLogo} alt={props.company} />
                    </a>
                </div>
            </div>
            <div className='col-xs-9 col-sm-9 col-lg-10'>
                <a href={linkJob} className='pio-item-link' data-item-id={props.jobId} target='_self'>
                    <h3 className='title job-title text-break'>{props.jobTitle}</h3>
                </a>
                <h3 className='company'>
                    {!props.isAnonymous ? (
                        <a className='job-search__company text-break' title={props.company} href={companyUrl}>
                            {props.company}
                        </a>
                    ) : (
                        <span className='job-search__company text-break' title={props.company}>
                            {props.company}
                        </span>
                    )}
                </h3>
                <div className='extra-info salary'>
                    <div className='text-info'>
                        <span className='hidden-xs job-search__location'>{locations}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const componentWithTranslation = withComponentTranslation(vi)(JobItem)

export default componentWithTranslation
