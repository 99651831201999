import styled from 'styled-components'

export const DivNavigation = styled.div`
    a.nav-item {
        color: #333;
    }

    .nav-item {
        display: inline-flex;
        align-items: center;
        padding: 10px 0;
        margin: 0 15px;
        line-height: 1;
        border-bottom: 2px solid transparent;
        transition: colr 0.3s, text-shadow 0.3s, border 0.3s;
        font-size: 14px;
        font-weight: 400;

        svg {
            transition: fill 0.3s;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: #005AFF;
            text-decoration: none;
            font-weight: 400;
            svg {
                fill: #005AFF;
            }
        }
        &.active {
            color: #005AFF;
            text-decoration: none;
            font-weight: 700;
            svg {
                fill: #005AFF;
            }
        }

        &.active {
            border-bottom: 2px solid #005AFF;
        }
    }

    .nav-icon {
        margin-right: 12px;
    }

    .nav-icon svg {
        width: 20px;
        height: 20px;
        fill: #333;
    }

    @media screen and (max-width: 991px) {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        background: #fff;
        margin-left: 0;
        padding-left: 15px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @media screen and (max-width: 425px) {
        padding-left: 4px;
        .nav-item {
            padding: 12px;
            margin: 0;
            font-weight: normal;

            &.active {
                font-weight: 600;
            }
        }

        .nav-icon {
            display: none;
        }
    }
`
