import IsEmpty from 'lodash/isEmpty'
import { buildJobsByCompanyUrl } from '../SearchResults/utils/utilities'
import { TJobItem } from './JobItem'

export function handleJobItem(props: TJobItem) {
    const locations = handleLocation(props.locations, props.language)
    const postedDate = handlePostedDate(props.postedDate)
    const linkJob = handleLinkJob(props.alias, props.jobId, props.language)
    const salary = handleSalary(props.salaryMax, props.salaryMin, props.isSalaryVisible, props.language)
    const companyUrl = buildJobsByCompanyUrl(props.companyId, props.company, props.language)

    return {
        linkJob,
        postedDate,
        salary,
        locations,
        companyUrl
    }
}

function handleLocation(propsLocation: any, language: string) {
    let location = propsLocation?.[1] || ''
    if (language === 'en') {
        location = propsLocation?.[2] || ''
    }
    location = !IsEmpty(location) ? location.join(', ') : location

    return location
}

function handlePostedDate(propsPostedDate: number) {
    const date = new Date(propsPostedDate * 1000)
    const postedDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()

    return postedDate
}

export function handleLinkJob(propsAlias: string, propsJobId: string, language: string) {
    let linkJob = process.env.VNW_JOBSEEKER_URL + '/' + propsAlias + '-' + propsJobId

    if (language === 'en') {
        linkJob += '-jd'
    } else {
        linkJob += '-jv'
    }
    linkJob += '/?utm_source_navi=searchPage&utm_medium_navi=noResult&utm_campaign_navi=noResult&utm_content_navi=SmartNaviIOP'

    return linkJob
}

function handleSalary(propsSalaryMax, propsSalaryMin, isSalaryVisible: boolean, language: string) {
    propsSalaryMin = propsSalaryMin ? Number(propsSalaryMin) : 0
    propsSalaryMax = propsSalaryMax ? Number(propsSalaryMax) : 0
    let salaryText = ''

    if (isSalaryVisible) {
        if (propsSalaryMin === 0 && propsSalaryMax > 0)
            salaryText = (language === 'en' ? 'Up to' : 'Tới') + ' $' + propsSalaryMax
        else if (propsSalaryMin > 0 && propsSalaryMax === 0)
            salaryText = (language === 'en' ? 'From' : 'Từ') + ' $' + propsSalaryMin
        else if (propsSalaryMin > 0 && propsSalaryMax > 0) salaryText = '$' + propsSalaryMin + ' - $' + propsSalaryMax
        else salaryText = language === 'en' ? 'Negotiable' : 'Thương lượng'
    } else salaryText = language === 'en' ? 'Negotiable' : 'Thương lượng'

    return salaryText
}
