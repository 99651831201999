import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

type TProps = {
    selector: string
    title: string
    description: string
    closeText: string
    active: boolean
    onClose: () => void
}

type StyledProductTourProps = {
    show: boolean
}

const StyledProductTour = styled.div<StyledProductTourProps>`
    > .tour-overlay {
        position: fixed; /* Sit on top of the page content */
        display: block; /* Hidden by default */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
        z-index: 205; /* Specify a stack order in case you're using a different order for other elements */
        cursor: pointer; /* Add a pointer on hover */
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    > .tour-box {
        padding: 16px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        background: #fff;
        display: ${(props) => (props.show ? 'block' : 'none')};
        max-width: 300px;

        > .arrow-up {
            position: absolute;
            left: 28px;
            transform: translateX(-50%);
            top: -8px;
            z-index: 5;
            width: 0px;
            height: 0px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid rgb(232, 232, 232);

            &:after {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 1.5px;
                z-index: 5;
                width: 0px;
                height: 0px;
                border-left: 6.5px solid transparent;
                border-right: 6.5px solid transparent;
                border-bottom: 6.5px solid white;
            }
        }

        > .title {
            font-weight: bold;
            margin-bottom: 4px;
            font-size: 14px;
        }

        > .description {
            font-size: 12px;
        }

        > .tour-actions {
            margin-top: 8px;
            text-align: right;

            > .close-action {
                color: #005aff;
                font-size: 12px;
                font-weight: bold;
                cursor: pointer;
                transition: color 0.3s;

                &:hover {
                    color: #FF7D55;
                }
            }
        }
    }
`

type SelectorPos = {
    top: number
    left: number
}

/**
 * How Product Tour works
 *
 * - Detect if user has seen the tour before, by setting prop `active`
 * - If not, when the browser stop scrolling, scroll to the tour's element, set by prop `selector`
 * - Start the tour, prevent the whole html from scrolling (by adding class `modal-open`)
 * - Show the whole screen dark overlay
 * - Set the selector z-index higher, so it will standout from the dark overlay
 * - Detect the selector position and set the guideline box to its positon
 * - Add `click` listner to selector element so when user click to selector, it will act as close button
 */
export default function ProductTour({ active = false, title, description, closeText, selector, onClose }: TProps) {
    const [tourStart, setTourStart] = useState(false)
    const [scrolledInSelector, setScrolledInSelector] = useState(false)
    const [selectorPos, setSelectorPos] = useState<SelectorPos | null>(null)

    useEffect(() => {
        if (!active) {
            return
        }

        /**
         * Detect if browser stop scrolling
         * We need to track when browser stop scrolling to start the product tour
         * Otherwise it's hard to detect Selector postion because when it's scrolling, the position offset will be different
         */
        let timer: any | null = null
        const handleScroll = () => {
            if (timer !== null) {
                clearTimeout(timer)
            }
            timer = setTimeout(function () {
                setTimeout(() => {
                    setTourStart(true)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }, 500)
            }, 150)
        }

        setTimeout(() => {
            if (!timer) {
                setTourStart(true)
            }
        }, 800)

        window.addEventListener('scroll', handleScroll, false)

        return () => {
            window.removeEventListener('scroll', handleScroll, false)
        }
    }, [active])

    useEffect(() => {
        if (!tourStart) {
            return
        }

        /**
         * .searchbar is having position: sticky that make z-index not working
         * so need to convert it to static
         * at this moment can't find a good way to do this rather than hard code fix on .searchbar
         */
        const stickySearchBar = document.querySelector<HTMLElement>('.searchbar')
        if (stickySearchBar) {
            stickySearchBar.style.position = 'static'
        }

        const handleSelectorClick = () => {
            handleCloseTour()
        }

        const selectorEl = document.querySelector<HTMLElement>(selector)
        if (selectorEl) {
            selectorEl.style.zIndex = '999'
            selectorEl.style.position = 'relative'

            selectorEl.scrollIntoView(false)

            setTimeout(() => {
                setScrolledInSelector(true)
            }, 500)

            selectorEl.addEventListener('click', handleSelectorClick)
        }

        document.body.classList.add('modal-open')
        document.documentElement.classList.add('modal-open')

        // Clean up
        return () => {
            const stickySearchBar = document.querySelector<HTMLElement>('.searchbar')
            if (stickySearchBar) {
                stickySearchBar.style.position = ''
            }

            document.body.classList.remove('modal-open')
            document.documentElement.classList.remove('modal-open')

            const selectorEl = document.querySelector<HTMLElement>(selector)
            if (selectorEl) {
                selectorEl.removeEventListener('click', handleSelectorClick)
            }
        }
    }, [tourStart])

    useEffect(() => {
        if (!scrolledInSelector) {
            return
        }

        const selectorEl = document.querySelector<HTMLElement>(selector)
        if (selectorEl) {
            const selectorRect = selectorEl.getBoundingClientRect()
            setSelectorPos({
                left: selectorRect.left + 16,
                top: selectorRect.bottom + 16
            })
        }
    }, [scrolledInSelector])

    const handleCloseTour = () => {
        setTourStart(false)
        onClose()
    }

    if (!active || typeof document === 'undefined') {
        return null
    }

    return createPortal(
        <StyledProductTour show={tourStart}>
            <div className={`tour-overlay ${scrolledInSelector ? 'show' : ''}`} />
            <div style={selectorPos ? { ...selectorPos, position: 'absolute', zIndex: 1000 } : {}} className='tour-box'>
                <span className='arrow-up' />
                <div className='title'>{title}</div>
                <div className='description'>{description}</div>
                <div className='tour-actions'>
                    <span className='close-action' onClick={() => handleCloseTour()}>
                        {closeText}
                    </span>
                </div>
            </div>
        </StyledProductTour>,
        document.querySelector('body') as Element
    )
}
