/**
 * The TypeDoc main module and namespace.
 *
 * The [[Application]] class holds the core logic of the cli application. All code related
 * to resolving reflections is stored in [[TypeDoc.Factories]], the actual data models can be found
 * in [[TypeDoc.Models]] and the final rendering is defined in [[TypeDoc.Output]].
 */

import { NextRouter } from 'next/router'
import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UrlCollectedParams } from '../../../handlers/path'
import { createGetSelectDataAction } from '../../SearchResults/components/SearchFilter/Sagas/initialData/initialDataActions'
import { PUT_UPDATE_PARTIAL_PARAMS } from '../../SearchResults/components/SearchFilter/Sagas/searchFilterJobs/searchFilterActions'
import filterHelper, { TSelectData } from '../../SearchResults/helpers/filterHelper'
import { urlParamSync } from '../../SearchResults/helpers/urlParamSync'
import { ISearchFilterParams } from '../../SearchResults/types/ISearchFilterParams'
import { ELanguage } from '../../SearchResults/utils/language'
import { encodeKeyword } from '../../SearchResults/utils/utilities'
import AppGlobalContext from '../context'
import translationResource from './../translations.json'

/**
 * Used for translation. You will need to provide the translation resource for this hook to work.
 * Also make sure that your component is under the `AppGlobalContext` context.
 *
 * There are 2 ways to translate:
 * 1. If you use a key, then you will need to provide both English & Vietnamese translation:
 *
 * ```typescript
 * {
 *     "vi": {
 *         "myKey": "bản dịch của tôi"
 *     },
 *     "en": {
 *         "myKey": "my translation"
 *     }
 * }
 * ```
 *
 * 2. If you use the text itself as the key, you only need to provide one set of translation:
 *
 * ```typescript
 * {
 *     "vi": {
 *         "my translation": "bản dịch của tôi"
 *     }
 * }
 * ```
 *
 * @param resource
 */
export function useGlobalTranslation(resource) {
    const context = useContext(AppGlobalContext)

    const language = context.langCode

    function translate(key: string) {
        return resource?.[language]?.[key] || key
    }

    return translate
}

/**
 * Hook to manage `shouldNotificationLoad` state
 * This state is used to render lazy loaded @vnw/notification package
 * Should load notification package when:
 * - Has mousedown event which means user has clicked something in the site
 * - User clicks on Bell icon
 *
 * @param triggerNotificationCb
 */
export function useInitNotification(triggerNotificationCb: () => void) {
    const [shouldNotificationLoad, setShouldNotificationLoad] = useState(false)

    useEffect(() => {
        if (typeof document === 'undefined') {
            return
        }

        const notificationNode = document.querySelector('.notification-icon')
        const mobileNotificationNode = document.querySelector('.burger-btn')
        const mobileNotificationMenuNode = document.querySelector('.menu-notification')

        const handleClickBell = () => {
            if (!shouldNotificationLoad) {
                setShouldNotificationLoad(true)

                setTimeout(() => {
                    triggerNotificationCb()
                }, 300)
            } else {
                triggerNotificationCb()
            }
        }

        const handleClickBurgerMenu = () => {
            if (!shouldNotificationLoad) {
                setShouldNotificationLoad(true)
            }
        }

        const loadNotification = () => {
            if (!shouldNotificationLoad) {
                setShouldNotificationLoad(true)
            }
        }

        notificationNode?.addEventListener('click', handleClickBell)
        mobileNotificationMenuNode?.addEventListener('click', handleClickBell)
        mobileNotificationNode?.addEventListener('click', handleClickBurgerMenu)
        window.addEventListener('mousedown', loadNotification, { once: true })

        return () => {
            notificationNode?.removeEventListener('click', handleClickBell)
            mobileNotificationMenuNode?.removeEventListener('click', handleClickBell)
            mobileNotificationNode?.removeEventListener('click', handleClickBurgerMenu)
            window.removeEventListener('mousedown', loadNotification)
        }
    }, [shouldNotificationLoad])

    return shouldNotificationLoad
}

/**
 * useUpdatePath hook is used to update URL reflects the search params
 * This hook watches for search filter params change and update the browser URL according to a predefined pattern
 * To update URL, we generally use router.push to push a new state to history
 * But we still have a case to replace state instead of push when we found historyReplaceState in localstorage
 * TODO: Comment why we need to use replace
 *
 * @param param0
 * @param language
 * @param router
 * @param selectData
 */
export function useUpdatePath(
    { keyword, locationId, categoryId = [], jobLevelId = -1, typeWorkingId = -1}: UrlCollectedParams,
    language: ELanguage,
    router: NextRouter,
    selectData: TSelectData
) {
    const isInitStore = useRef(true)
    const translate = useGlobalTranslation(translationResource)

    useEffect(() => {
        if (isInitStore.current) {
            isInitStore.current = false
            if (keyword === '') {
                return
            }
        }

        if (Object.keys(selectData).length === 0) {
            return
        }

        const replaceHistory: boolean = localStorage.getItem('historyReplaceState') === 'true'
        if (replaceHistory) localStorage.setItem('historyReplaceState', 'false')

        const queryString = window?.location?.search
        const safeKeyword = keyword ? encodeKeyword(keyword) : ''

        let categorySlug = ''
        if (typeof categoryId === 'object') {
            categoryId.map((item, index) => {
                categorySlug += `${index > 0 ? '-' : ''}${selectData.categoryById?.[item]?.slug}`
            })
        }
        let locationSlug = ''
        if (typeof locationId === 'object') {
            locationId.map((item, index) => {
                locationSlug += `${index > 0 ? '-' : ''}${selectData.locationById?.[item]?.slug}`
            })
        }

        const typeWorkingSlug = urlParamSync.buildTypeWorkingSlug(typeWorkingId, selectData.typeWorking, language)

        const hasFilter = urlParamSync.hasAnyFilter({
            categoryId,
            locationId,
            typeWorkingId,
            jobLevelId
        })

        const searchSlug = urlParamSync._buildSearchSlugText(
            safeKeyword,
            categorySlug,
            locationSlug,
            language,
            false,
            typeWorkingSlug,
            hasFilter
        )
        const searchCodeStr = urlParamSync._buildSearchCode({
            query: keyword,
            locationId,
            categoryId,
            jobLevelId,
            typeWorkingId
        })

        const isAllJobs = keyword === '' && locationId.length === 0 && categoryId.length === 0
        const haveOnlyKeyword = keyword !== '' && locationId.length === 0 && categoryId.length === 0 // Have only keyword is a special case

        if (router.pathname === '/interview') {
            let interviewPath = keyword === '' ? translate('all-jobs') : `${safeKeyword}-ik`
            if (queryString !== '') {
                interviewPath += queryString
            }

            if (replaceHistory) {
                router.replace(router.pathname, `${translate('/interview')}/${interviewPath}`, { shallow: true })
            } else {
                router.push(router.pathname, `${translate('/interview')}/${interviewPath}`, { shallow: true })
            }
        }

        if (router.pathname === '/salary') {
            let path = isAllJobs
                ? translate('all-jobs')
                : haveOnlyKeyword
                ? `${safeKeyword}-sk`
                : `${searchSlug}-${searchCodeStr}`
            if (queryString !== '') {
                path += queryString
            }

            if (replaceHistory) {
                router.replace(router.pathname, `${translate('/salary')}/${path}`, { shallow: true })
            } else {
                router.push(router.pathname, `${translate('/salary')}/${path}`, { shallow: true })
            }
        }
    }, [keyword, locationId, categoryId, jobLevelId, typeWorkingId])
}

/**
 * Hook to update search params to Redux store by combining params in URL and Session Storage
 *
 * @param serverParams Also known as URL params
 * @param requireSelectData Whether it should call createGetSelectDataAction or not
 * @param language
 */
export function useInitParams(
    serverParams: Partial<ISearchFilterParams>,
    storedParams: Partial<ISearchFilterParams>,
    requireSelectData: boolean,
    language: ELanguage,
    pageName: string
) {
    const dispatch = useDispatch()

    useEffect(() => {
        const defaultParams = filterHelper.getDefaultFilterableParams()

        const inUrlParams: Partial<ISearchFilterParams> = {}

        inUrlParams.query = storedParams.query !== '' ? storedParams.query : serverParams.query

        inUrlParams.locationId =
            storedParams.locationId && storedParams.locationId.length > 0
                ? storedParams.locationId
                : serverParams.locationId

        if (serverParams.categoryId && serverParams.categoryId.length > 0) {
            inUrlParams.categoryId = serverParams.categoryId
        }

        if (serverParams.jobLevelId && serverParams.jobLevelId > -1) {
            inUrlParams.jobLevelId = serverParams.jobLevelId
        }

        const storedSessionParams = sessionStorage.getItem('GlobalParams')
        let pageSessionParams: Partial<ISearchFilterParams> = {}
        if (storedSessionParams) {
            pageSessionParams = JSON.parse(storedSessionParams)[pageName] || {}
        }

        if (requireSelectData) {
            dispatch(
                createGetSelectDataAction(() => {
                    dispatch({
                        type: PUT_UPDATE_PARTIAL_PARAMS,
                        payload: { ...defaultParams, ...pageSessionParams, ...inUrlParams },
                        from: 'useInitParams'
                    })
                }, language)
            )
        } else {
            dispatch({
                type: PUT_UPDATE_PARTIAL_PARAMS,
                payload: { ...defaultParams, ...pageSessionParams, ...inUrlParams },
                from: 'useInitParams'
            })
        }
    }, [])
}
