const translations = {
    'Jobs': 'Việc Làm',
    'Management Jobs': 'Việc Làm Quản Lý',
    '/job-channel/top-management': '/kenh-viec-lam/quan-ly',
    '/job-search/all-jobs': '/tim-viec-lam/tim-tat-ca-viec-lam',
    '/interview/all-jobs': '/cau-hoi-phong-van/tat-ca-viec-lam',
    'Interview Question': 'Câu Hỏi Phỏng Vấn',
    '/management-jobs': '/viec-lam-quan-ly',
    'all-jobs': 'tat-ca-viec-lam',
    'Salary': 'Mức Lương',
    '/salary/all-jobs': '/muc-luong/tat-ca-viec-lam'
}

export default translations
