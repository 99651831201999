import { EPages, ETopBannerZoneName, Footer, initialDataProvider, TUser, Header } from '@microfrontends/app-shell-v2'
import { Utils } from '@microfrontends/react-components'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { extractInterviewParamsFromPath, extractSalaryParamsFromPath, UrlCollectedParams } from '../../handlers/path'
import { EMode } from '../../pages'
import Navigation from '../Navigation'
import ProductTour from '../ProductTour'
import { ISearchEvent } from '../SearchResults/components/SearchFilter'
import { PUT_UPDATE_PARTIAL_PARAMS } from '../SearchResults/components/SearchFilter/Sagas/searchFilterJobs/searchFilterActions'
import { SET_AUTH_USER } from '../SearchResults/global/searchResultGlobalMiscActions'
import { TGlobalMiscStore } from '../SearchResults/global/searchResultGlobalMiscReducer'
import filterHelper, { fireGlobalSearchEvent, TSelectData } from '../SearchResults/helpers/filterHelper'
import { storeRecentSearch } from '../SearchResults/services/recentSearches'
import { ISearchFilterParams } from '../SearchResults/types/ISearchFilterParams'
import { ELanguage } from '../SearchResults/utils/language'
import { FullWidthBlock } from '../UiComponents/Block'
import { buildDynamicMeta } from './helper/metaDataHelper'
import { SESSION_PARAMS } from './helper/types'
import { useGlobalTranslation, useInitParams, useUpdatePath } from './hooks'
import MetaTags from './MetaTags'
import translationResource from './translations.json'
import NotificationComponent from '../Notification'
import { decrypt } from '../../handlers/auth/cryptor'

type TMasterLayoutProps = {
    user: TUser | null
    language: ELanguage
    slotAside?: ReactNode
    slotBottom?: ReactNode
    children: ReactNode
    pageName: EPages
    hiddenRoutes?: string[]
    navigateSearch?: boolean
    userIp?: string
    questionAnswers?: any[]
    cornerColor?: string
}

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    refreshJWTUrl: `${process.env.VNW_BASE_URL}/my-career-center/employer-message/?action=renewFirebaseJWT`
}

const { locationId, salaryCurrency, ...clearableParams } = filterHelper.getDefaultFilterableParams()

const TOUR_KEY_MAPPING = {
    [EPages.SALARY]: 'VNW_SALARY_TOUR_EXISTED',
    [EPages.INTERVIEW]: 'VNW_INTERVIEW_TOUR_EXISTED'
}

/**
 * MasterLayout component is responsible for:
 * - Include & Integrate Header, Footer, Notification component
 * - Collect search params from URL and update it to store
 * - Handle update URL when there are new search params
 * - Control layout of a page: Child component will be the main content, sub content i.e: Aside block will be provided as prop
 *
 * @param props
 */
export default function MasterLayout(props: TMasterLayoutProps & Partial<UrlCollectedParams>) {
    const {
        user: backendUser,
        language,
        keyword: rawKeyword,
        categoryId = [],
        locationId = [],
        jobLevelId = -1,
        pageName,
        hiddenRoutes = [],
        userIp = '',
        questionAnswers,
        cornerColor = '#fff'
    } = props

    // State for display dimmed tooltip aka Product Tour
    const [tourEnable, setTourEnable] = useState(false)
    /**
     * A Flag to know if it's required to get meta data
     * If a page does not show a search filter, so it will not need meta data. E.g: Interview page
     */
    const noFilterPages = [EPages.INTERVIEW]
    const requireSelectData = !(noFilterPages.indexOf(pageName as EPages) > -1)

    const dispatch = useDispatch()
    const router = useRouter()
    const translate = useGlobalTranslation(translationResource)
    const {
        query: storedKeyword,
        locationId: storedLocationId,
        categoryId: storedCategoryId,
        jobLevelId: storedJobLevelId,
        typeWorkingId: storedTypeWorkingId,
        districtId: storeDist
    } = useSelector<any, ISearchFilterParams>((state) => {
        return state.searchFilter.lastParams
    })

    const { user: storedUser } = useSelector<any, TGlobalMiscStore>((state) => {
        return state.globalMisc
    })

    const user = storedUser && storedUser.userId ? storedUser : backendUser ? { ...backendUser, userIp: userIp } : null

    const isInitParams = useSelector<any, boolean>((state) => state.searchFilter?.isInitParams || false)
    const keyword = isInitParams ? rawKeyword : storedKeyword

    const selectData = useSelector<any, TSelectData>((state) => {
        return state.searchFilter.selectData
    })

    const initSelectData = async () => {
        await initialDataProvider.initSelectData({
            selectDataKey: ['jobFunction', 'jobFunctionCount', 'industry', 'level'],
            mode: process.env.APP_SHELL_MODE as any,
            langCode: language
        })
    }

    useInitParams(
        { query: keyword, locationId, categoryId, jobLevelId },
        { query: storedKeyword, locationId: storedLocationId },
        requireSelectData,
        language,
        pageName
    )

    // Component Did Mount
    const tourKey = TOUR_KEY_MAPPING[pageName] || 'default'
    useEffect(() => {
        try {
            const isTourEnable = !localStorage.getItem(tourKey)
            setTourEnable(isTourEnable)
        } catch (error) {
            setTourEnable(false)
        }

        if (!storedUser) {
            dispatch({
                type: SET_AUTH_USER,
                payload: {
                    ...user,
                    userIp: userIp
                }
            })
        }

        /**
         * Update params from SEARCH event to store
         *
         * @todo TODO:
         * - More explaination
         * - Move code into a new custom hook
         */
        const handleGlobalSearchEvent = (e) => {
            /**
             * - Extract params from SEARCH event
             * - If clearCurrentParams, we need to provide default filter params values along side with SEARCH event params
             * otherwise it will keep the current stored params
             * - Dispatch update action to store
             */
            const searchEvent = e as ISearchEvent
            const { params: eventParams, clearCurrentParams = false } = searchEvent.detail
            const updatingParams = clearCurrentParams ? { ...clearableParams, ...eventParams } : { ...eventParams }
            if (pageName === EPages.SALARY && updatingParams.query) {
                updatingParams.query = Utils.transformToLatin(updatingParams.query)
            }

            dispatch({
                type: PUT_UPDATE_PARTIAL_PARAMS,
                payload: updatingParams
            })

            /**
             * If user perform new search, scroll to top and view new refresh content
             */
            if (typeof window !== 'undefined') {
                window.scrollTo(0, 0)
            }

            /**
             * Put a GlobalParams to sessionStorage
             * For some main filters (location, category), we push these filter params onto the URL so the URL will keep the params for us
             * But for other filters (company size, salary currency), we don't so if we change language, these params will be lost
             * That why we ned to keep them in sessionStorage
             */
            const storedSessionParams = sessionStorage.getItem('GlobalParams')
            const sessionParams: SESSION_PARAMS = storedSessionParams ? JSON.parse(storedSessionParams) : null
            const pageSessionParams = sessionParams ? { ...sessionParams[pageName] } : {}

            if (eventParams.salaryCurrency) {
                pageSessionParams.salaryCurrency = eventParams.salaryCurrency
            }

            if (eventParams.companySizeId) {
                pageSessionParams.companySizeId = eventParams.companySizeId
            }

            if (eventParams.jobLevelId) {
                pageSessionParams.jobLevelId = eventParams.jobLevelId
            }

            if (eventParams.categoryId) {
                pageSessionParams.categoryId = eventParams.categoryId
            }

            if (eventParams.indexName) {
                pageSessionParams.indexName = eventParams.indexName
            }

            sessionStorage.setItem(
                'GlobalParams',
                JSON.stringify({
                    ...sessionParams,
                    [pageName]: pageSessionParams
                })
            )

            if (eventParams.query) {
                storeRecentSearch({
                    keyword: eventParams.query
                })
            }
        }

        /**
         * @deprecated as soon as we replace "next/link" with <SearchLink>
         *
         * Watch for Route changes and extract information from route
         * Caution: This route change handle should only work on same route change
         * For example:
         * Do: /interview/php-kw => /interview/java-kw
         * Dont: /interview/php-kw => /salary/php-sk
         *
         * @param url
         */
        const handleRouteChange = (url) => {
            if (router.pathname === '/interview') {
                const params = extractInterviewParamsFromPath(url)

                if (params) {
                    fireGlobalSearchEvent({ query: params.keyword, ...params }, 'MasterPage:SyncKeyword')
                }
            }
        }

        const handleBrowserBack = () => {
            if (router.pathname === '/salary') {
                const params = extractSalaryParamsFromPath(window.location.pathname)

                if (params) {
                    fireGlobalSearchEvent({ query: params.keyword, ...params }, 'MasterPage:BrowserBack')
                }
            }
        }

        initSelectData()
        window.addEventListener('SEARCH', handleGlobalSearchEvent)
        router.events.on('routeChangeStart', handleRouteChange)
        window.addEventListener('popstate', handleBrowserBack)

        return () => {
            window.removeEventListener('SEARCH', handleGlobalSearchEvent)
            router.events.off('routeChangeStart', handleRouteChange)
            window.removeEventListener('popstate', handleBrowserBack)
        }
    }, [])

    useUpdatePath(
        {
            keyword: pageName === EPages.SALARY ? Utils.transformToLatin(storedKeyword) : storedKeyword,
            locationId: storedLocationId,
            categoryId: storedCategoryId,
            jobLevelId: storedJobLevelId,
            typeWorkingId: storedTypeWorkingId
        },
        language,
        router,
        selectData
    )

    /**
     * Watch and create global SEARCH event for keyword, location, and category
     * Other components such as AppShell Header are lisnening this event and consume keyword, location, and category params
     */
    useEffect(() => {
        if (storedKeyword === '' && storedLocationId.length === 0 && storedCategoryId.length === 0) {
            return
        }
        if (pageName === EPages.SALARY) {
            return
        }
        fireGlobalSearchEvent(
            {
                query: storedKeyword,
                locationId: storedLocationId,
                categoryId: storedCategoryId,
                districtId: storeDist
            },
            'MasterPage:CurrentStoreToAppShellHeader'
        )
    }, [storedKeyword, storedLocationId, storedCategoryId])

    const metaInfo = buildDynamicMeta(keyword || '', pageName, translate)

    const notificationProps = {
        langCode: language,
        firebaseConfig: firebaseConfig,
        jobSeekerUrl: process.env.VNW_BASE_URL,
        user: { ...user, accessToken: decrypt(user?.accessToken ?? '') },
        baseMsUrl: process.env.MICROSERVICE_DOMAIN
    }

    const includeTour = [EPages.INTERVIEW, EPages.SALARY].indexOf(pageName as EPages) > -1

    return (
        <div>
            <Header
                mode={process.env.APP_SHELL_MODE as EMode}
                langCode={language}
                user={user as TUser}
                userIp={userIp}
                topBannerZone={ETopBannerZoneName.EMPTY}
                pageName={pageName}
                responsive={{ type: 'search-page', cornerColor }}
            />

            <MetaTags language={language} {...metaInfo} pageName={pageName} questionAnswers={questionAnswers} />

            <ToastContainer />

            <div className='main-content'>
                <div className='stickWithHeader'>
                    {pageName !== EPages.NONE ? (
                        <FullWidthBlock hasContainer>
                            <Navigation language={language} hiddenRoutes={hiddenRoutes} />
                        </FullWidthBlock>
                    ) : null}
                </div>
                <div className='contentContainer'>
                    <div className='row no-gutters justify-content-center'>
                        <div
                            className={`col-md-8 alignedCol ${!props.slotAside ? 'alignedCol-full' : ''}`}
                            style={{
                                zIndex: 1
                            }}
                        >
                            {props.children}
                        </div>
                        {props.slotAside && <div className='col main-right-col'>{props.slotAside}</div>}
                    </div>
                    {props.slotBottom && (
                        <div className='row no-gutters justify-content-center'>
                            <div className='col-md-8 alignedCol'>{props.slotBottom}</div>
                        </div>
                    )}
                </div>
            </div>

            {includeTour && (
                <ProductTour
                    selector='.searchbox'
                    active={tourEnable && keyword === ''}
                    title={translate(`${pageName}_tour_title`)}
                    description={translate(`${pageName}_tour_desc`)}
                    closeText={translate(`${pageName}_tour_action`)}
                    onClose={() => {
                        setTourEnable(false)
                        try {
                            localStorage.setItem(tourKey, 'true')
                        } catch (error) {
                            console.error('Error to persist tour key')
                        }
                    }}
                />
            )}

            <NotificationComponent {...notificationProps} />

            <Footer
                mode={process.env.APP_SHELL_MODE as EMode}
                langCode={language}
                user={user as any}
                bottomSEOContent={{
                    html: ``,
                    ssr: false
                }}
            />
        </div>
    )
}
