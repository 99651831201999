import React from 'react'
import { TAuthUser } from '../../../handlers/auth/interface'
import { ELanguage } from '../../SearchResults/utils/language'

export type TAppGlobalContext = {
    langCode: ELanguage
    user: TAuthUser | null
}

const AppGlobalContext = React.createContext<TAppGlobalContext>({
    langCode: ELanguage.VI,
    user: null
})

export default AppGlobalContext
