import { TAuthUser } from './interface'

const AUTH_API: string | undefined = process.env.AUTH_API
const AUTH_API_CLIENT = `${process.env.VNW_JOBSEEKER_URL}/?action=auth`

export async function getUserInfo(
    jsToken: string,
    wsToken: string,
    serverCall: boolean = true
): Promise<TAuthUser | null> {
    const path = serverCall ? AUTH_API : AUTH_API_CLIENT

    if (!path) return null

    const body = serverCall
        ? JSON.stringify({
              jsToken: jsToken,
              wsToken: wsToken
          })
        : null

    const response = await fetch(path, {
        method: 'post',
        headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json'
        },
        body: body
    })

    if (response.status === 200) {
        const responseData = await response.json()

        if (!serverCall) {
            return responseData?.data
        }

        const singleData = responseData.data[0]

        return {
            ...singleData.id,
            ...singleData.attributes
        }
    }

    return null
}
