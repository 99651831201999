// boostrap is conflict with tailwindcss
// so only use boostrap in old layout
import '@microfrontends/bootstrap/dist/css/bootstrap.min.css'
import { useEffect, useState } from 'react'
import RecommendService from '../../packages/SearchResults/services/recommendService'
import { getLastViewJobId } from '../SearchResults/components/NoResult/logics/recommandJobsHelper'
import JobItem from './JobItem'
import { ELanguage } from '../../packages/SearchResults/utils/language'
import { EPages } from '../../helpers/constant'
import { withGlobalTranslation } from '../../handlers/translation/withGlobalTranslation'
import { NoResult } from '@microfrontends/react-components'
import vi from './vi.json'
import StyledRecommendJobs from './RecommendJobs.css'
import Link from 'next/link'

const BASE_URL_API = process.env.VNW_PREDICTIONIO_URL || 'https://ms.vietnamworks.com'

export type TRecommendJobs = {
    langCode: ELanguage
    userId: string
    pageName: EPages
    className: string
    translate: (string) => string
}

const RecommendJobs = (props) => {
    const [recommendedJobs, setRecommendJobs] = useState([])
    const { translate, userId, langCode, pageName, className, title } = props
    const isUserLoggedIn: boolean = userId > 0

    useEffect(() => {
        ;(async () => {
            const recommendService = new RecommendService(BASE_URL_API)
            const lastJobId = getLastViewJobId()

            const recommendedJobs = await recommendService.getRecommendJobs(lastJobId, userId, 10, 'get', langCode)
            if (recommendedJobs && recommendedJobs.length !== 0) {
                setRecommendJobs(recommendedJobs)
            }
        })()
    }, [])

    return (
        <StyledRecommendJobs>
            <div className={`noResultWrapper animated fadeIn ${className}`}>
                <NoResult pageName={pageName} title={`${translate(title)}`} language={langCode}>
                    {props.children}
                </NoResult>
            </div>
            {recommendedJobs.length > 0 ? (
                <div className='noResultBlock mtBlock'>
                    <div className='wrapper-no-job'>
                        <div className='recommend-job-title'>
                            <h3 className='m-t-lg m-l'>
                                {translate('Here are some suggestions that we think might interest you')}
                            </h3>

                            <Link href={translate('/job-search/all-jobs')}>
                                <a className='desktop-view'>{translate('View All')}</a>
                            </Link>
                        </div>
                        <div className='recommend-jobs-wrapper'>
                            {recommendedJobs.map((item, index) => {
                                return <JobItem key={index} {...item} language={langCode} isUserLoggedIn={isUserLoggedIn} />
                            })}
                        </div>

                        <div className='mobile-view'>
                            <Link href={translate('/job-search/all-jobs')}>
                                <a>{translate('View All')}</a>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : null}
        </StyledRecommendJobs>
    )
}

export default withGlobalTranslation(RecommendJobs, vi)
