import React, { ReactNode } from 'react'
import { Container } from '@microfrontends/react-components'
import styled from 'styled-components'

// TODO: Typing

const StyledFullWidthBlock = styled.div`
    background: white;
    width: 100%;
    margin-bottom: 8px;

    .container-fluid {
        padding: 0;
    }

    @media screen and (max-width: 991px) {
        padding-left: 0;
    }

    @media screen and (max-width: 768px) {
        padding: 0;
    }
`

const BlockWrapper = styled.div`
    .anchor-logoIntech-searchPage {
        margin-right: 0;
    }
`

type TFullWidthBlockProps = {
    noPadding?: boolean
    children: ReactNode
    hasContainer?: boolean
}

export function FullWidthBlock(props: TFullWidthBlockProps) {
    return (
        <StyledFullWidthBlock {...props}>
            <Container>
                <BlockWrapper className={`${props.hasContainer ? 'container-fluid' : ''} stickWithHeader`}>
                    {props.children}
                </BlockWrapper>
            </Container>
        </StyledFullWidthBlock>
    )
}
