import { ReactElement } from 'react'
import { EPageTab } from '../SearchResults/types/EPageTag'
import {
    IconCase,
    IconCaseFilled,
    IconManager,
    IconManagerFilled
} from './icons'

type TMenuItem = {
    icon: ReactElement
    activeIcon?: ReactElement
    name: string
    route: string
    routeAlias?: string
    url?: string
    pageTab: EPageTab
}

export type TNavigationMenuItem = {
    icon: ReactElement
    activeIcon?: ReactElement
    name: string
    route: string
    routeAlias?: string
    url?: string
    pageTab: EPageTab
}

export const menuList: TMenuItem[] = [
    {
        icon: <IconCase />,
        activeIcon: <IconCaseFilled />,
        name: 'Jobs',
        route: '/',
        routeAlias: '/job-search/all-jobs',
        pageTab: EPageTab.JOBS
    },
    {
        icon: <IconManager />,
        activeIcon: <IconManagerFilled />,
        name: 'Management Jobs',
        route: '/?tab=management',
        routeAlias: '/management-jobs',
        pageTab: EPageTab.MANAGEMENT_JOBS
    },
]
