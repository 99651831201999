import NextHead from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { assetUrl, getPathFromUrl } from '../../helpers/common'
import { EPages } from '../../helpers/constant'
import { useTranslation } from '../SearchResults/hooks'
import { ELanguage } from '../SearchResults/utils/language'
import translationResource from './translations.json'
import Scripts from './Scripts'
import { formatCanonicalUrl } from '@microfrontends/react-components'

const JS_BASE_URL = process.env.VNW_JOBSEEKER_URL
const OG_IMAGE = process.env.VNW_IMAGES_URL + '/img/vnw_logo.png'

export type TPageMetaTagsProps = {
    title?: string
    description?: string
    keywords?: string
    ogImageUrl?: string
    language?: ELanguage
    pageName: string
    questionAnswers?: any[]
}

export default function MetaTags(props: TPageMetaTagsProps) {
    const {
        title = 'defautMetaTitle',
        description = 'defautMetaDesc',
        keywords = 'defaultMetaKeywords',
        ogImageUrl = OG_IMAGE,
        language = ELanguage.VI,
        pageName,
        questionAnswers
    } = props

    const translate = useTranslation(translationResource)
    const translateVI = useTranslation(translationResource, ELanguage.VI)
    const router = useRouter()
    let pathSplit = router.asPath.split('/')
    for (let idx = 0; idx < pathSplit.length; idx++) {
        pathSplit[idx] = translateVI(pathSplit[idx])
    }

    let currentUrl = getPathFromUrl(`${JS_BASE_URL}${pathSplit.join('/')}`)
    let robotsContent = language === ELanguage.VI ? 'index, follow' : 'noindex, nofollow'

    const isHasUpperCase = (string) => /[A-Z]/.test(string)
    if (isHasUpperCase(decodeURI(currentUrl))) {
        currentUrl = decodeURI(currentUrl).toLowerCase()
        robotsContent = 'noindex, nofollow'
    }
    if (router.asPath.indexOf('?') !== -1) {
        robotsContent = 'noindex, nofollow'
    }
    if (
        questionAnswers &&
        questionAnswers.length === 0 &&
        pageName === EPages.INTERVIEW &&
        !currentUrl.includes('/cau-hoi-phong-van/tat-ca-viec-lam')
    ) {
        robotsContent = 'noindex, nofollow'
    }

    if (router.asPath === '/404' || router.asPath === '/410') {
        robotsContent = 'noindex, nofollow'
    }

    // A page cannot (it seems) reference an image for its [summary_large_image] twitter card,
    // which another page's [summary_large_image] twitter card has already referenced.
    // If it attempts to, the image will not display.
    // (This restriction does not apply to the Native iOS Twitter App.)
    // So we need to to create a unique id for the image link everytime we render
    const twitterImageUID = Date.now().toString(36)

    let updatedTitle: string = ''
    if (pageName === EPages.INTERVIEW || (pageName === EPages.SALARY && language === ELanguage.VI)) {
        updatedTitle = translate(title)
    } else {
        updatedTitle = `${translate(title)} | VietnamWorks`
    }

    return (
        <>
            <NextHead>
                <meta charSet='UTF-8' />
                <title>{updatedTitle}</title>
                <meta name='description' content={description} />
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1 maximum-scale=3.0'
                />
                <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
                <link rel='shortcut icon' href={assetUrl('/images/favicon.ico')} type='image/x-icon' />
                <link rel='icon' sizes='192x192' href={assetUrl('/images/vnw_192.png')} />
                <link rel='apple-touch-icon' href={assetUrl('/images/vnw_512.png')} />
                <meta name='theme-color' content='#005aff' />
                <meta name='robots' content={robotsContent} />
                <meta name='keywords' content={keywords} />
                <meta name='format-detection' content='telephone=no' />
                <meta property='og:url' content={currentUrl} />
                <meta property='og:title' content={title} />
                <meta property='og:description' content={description} />
                <meta name='twitter:site' content={currentUrl} />
                <meta name='twitter:title' content={title} />
                <meta name='twitter:description' content={description} />
                <meta name='twitter:image' content={ogImageUrl + `?${twitterImageUID}`} />
                <meta name='twitter:image:src' content={ogImageUrl + `?${twitterImageUID}`} />
                <meta name='twitter:card' content='summary_large_image' />
                <meta property='og:image' content={ogImageUrl} />
                <meta property='og:image:secure_url' content={ogImageUrl} />
                <meta property='og:image:width' content='526' />
                <meta property='og:image:height' content='275' />
                <link rel='canonical' href={formatCanonicalUrl(currentUrl)} />
            </NextHead>

            <Scripts />
        </>
    )
}
