// TODO [Refactor] There are 2 similar classes: this and the `UrlParamSync` class
// Should have a reflection on how to refactor this

import { EPages } from '../helpers/constant'
import { urlParamSync } from '../packages/SearchResults/helpers/urlParamSync'
import { decodeKeyword } from '../packages/SearchResults/utils/utilities'
import {SORT_DEFAULT_INDEX} from "../packages/SearchResults/components/JobHead";
import {sortByUrlParam} from "../packages/SearchResults/components/SearchFilter/constants";

/**
 * Params that can be collected from URL
 */
export type UrlCollectedParams = {
    keyword: string
    locationId: number[]
    categoryId?: number[]
    jobLevelId?: number
    typeWorkingId?: number
    indexName?: string
}

export const DEFAULT_URL_COLLECTED_PARAMS: UrlCollectedParams = {
    keyword: '',
    locationId: [],
    categoryId: [],
    jobLevelId: -1,
    indexName: SORT_DEFAULT_INDEX
}

export function extractParamsFromSlug(pathSlug: string, pageName: string) {
    const params: UrlCollectedParams = {
        keyword: '',
        locationId: [],
        categoryId: [],
        jobLevelId: -1,
        indexName: SORT_DEFAULT_INDEX
    }

    if (!pathSlug) {
        return params
    }

    /**
     * Detect keyword in Salary page, the URL has pattern: {keyword}-sk
     */
    const regexMatchKeyword = /(.*)-sk$/gm
    const matches = regexMatchKeyword.exec(pathSlug)
    if (matches) {
        params.keyword = matches[1]
    } else {
        // If the URL has this pattern: {something}+{somethingElse}
        // we expect that "something" is a keyword
        const urlPartsWithKeyword = pathSlug.split('+')
        if (urlPartsWithKeyword.length > 1) {
            params.keyword = urlPartsWithKeyword[0]
        }
    }

    /**
     * Detect location, category from the URL
     * Location pattern: v{locationId}
     * Category pattern: i{categoryId}
     *
     * For salary page, the location, category pattern is located at the end of the URL
     * E.g: /salary/{path}-i35v29
     */
    const urlParts = pathSlug.split('-')

    let filterCode = urlParts?.[urlParts.length - 2] || ''
    if (pageName === EPages.SALARY) {
        filterCode = urlParts?.[urlParts.length - 1] || ''
    }

    const splittedParams = urlParamSync.splitParams(filterCode)
    splittedParams.map((paramItem) => {
        if (paramItem.key === 'i') {
            params.categoryId = paramItem.value as number[]
        } else if (paramItem.key === 'v') {
            params.locationId = paramItem.value as number[]
        } else if (paramItem.key === 'l') {
            // jobLevelId doesn't support multiple value
            params.jobLevelId = (paramItem.value[0] !== undefined ? paramItem.value[0] : -1) as number
        }
        else if (paramItem.key === 'sorting') {
            params.indexName = sortByUrlParam[sortByUrlParam[paramItem.value[0] as string]]
        }
    })

    return { ...params, keyword: decodeKeyword(params.keyword) }
}

export function extractInterviewParamsFromPath(path): Partial<UrlCollectedParams> | null {
    const regexInterviewKeyword = /^\/(cau-hoi-phong-van|interview)\/(.*)-ik$/gm
    const regexInterviewAll = /^\/(cau-hoi-phong-van\/tat-ca-viec-lam|interview\/all-jobs)$/gm

    const matches = regexInterviewKeyword.exec(path)
    const matchesAll = regexInterviewAll.exec(path)

    let keywordToUpdate: string | null = null

    if (matches) {
        const keyword = matches[2]

        if (keyword) {
            keywordToUpdate = decodeURIComponent(keyword)
        }
    }

    if (matchesAll) {
        keywordToUpdate = ''
    }

    return keywordToUpdate
        ? {
              keyword: decodeKeyword(keywordToUpdate)
          }
        : null
}

export function extractSalaryParamsFromPath(path): Partial<UrlCollectedParams> | null {
    const regexSalaryAll = /^\/(muc-luong\/tat-ca-viec-lam|salary\/all-jobs)$/gm
    const matchesAll = regexSalaryAll.exec(path)

    if (matchesAll) {
        return DEFAULT_URL_COLLECTED_PARAMS
    }

    const params = { ...DEFAULT_URL_COLLECTED_PARAMS }

    const regexSalaryOnlyKeyword = /^\/(salary|muc-luong)\/(.*)-sk$/gm
    const matches = regexSalaryOnlyKeyword.exec(path)

    let keywordToUpdate: string | null = null

    if (matches) {
        const keyword = matches[2]
        keywordToUpdate = keyword
    } else {
        const pathParts = path.split('/')
        const pahtSlug = pathParts[pathParts.length - 1]
        const decodedPathSlug = decodeURIComponent(pahtSlug)
        const urlPartsWithKeyword = decodedPathSlug.split('+')
        let filterPart = ''

        if (urlPartsWithKeyword.length > 1) {
            // has keyword
            keywordToUpdate = urlPartsWithKeyword[0]
            filterPart = urlPartsWithKeyword[1]
        } else {
            filterPart = urlPartsWithKeyword[0]
        }

        if (filterPart !== '') {
            const splittedParams = urlParamSync.splitParams(filterPart)
            splittedParams.map((paramItem) => {
                if (paramItem.key === 'i') {
                    params.categoryId = paramItem.value as number[]
                } else if (paramItem.key === 'v') {
                    params.locationId = paramItem.value as number[]
                } else if (paramItem.key === 'l') {
                    // jobLevelId doesn't support multiple value
                    params.jobLevelId = (paramItem.value[0] !== undefined ? paramItem.value[0] : -1) as number
                } else if (paramItem.key === 'sorting') {
                    params.indexName = paramItem.value[0] as string
                }
            })
        }
    }

    return {
        ...params,
        keyword: keywordToUpdate ? decodeKeyword(decodeURIComponent(keywordToUpdate)) : ''
    }
}
