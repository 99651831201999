import styled from 'styled-components'


export default styled.div`
    .noResultBlock {
        border: none;
    }
    
    .noResultBlock .wrapper-no-job .m-l {
        &:after {
            width: 0;
            height: 0;
        }
    }

    .wrapper-no-job {
        padding: 0;
        border: 0;
        background-color: #f7f7f7;
    }

    .recommend-job-title {
        margin-top: 35px;

        & h3 {
            display: inline-flex;
        }

        & a {
            float: right;
            color: #005aff;

            &:hover {
                color: #ff7d55;
            }
        }
    }

    .recommend-jobs-wrapper {
        display: flex;
        flex-flow: row wrap;
        gap: 20px;
        justify-content: space-between;
    }

    .job-item {
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 7px;
        padding: 10px;
        margin-left: 0;
        margin-right: 0;
        width: 48%;
        padding: 15px 0 !important;

        & img {
            object-fit: scale-down;
            width: 70px !important;
        }

        & .job-title {
            display: block !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            height: 45px;
            margin-bottom: 0 !important;
            text-overflow: ellipsis;
        }

        & .company {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500 !important;
            line-height: 19px !important;
        }

        & .job-search__location {
            color: #333 !important;
            font-weight: 500 !important;
        }

        &:after {
            width: 0 !important;
            height: 0 !important;
        }

        &:last-child {
            padding-bottom: 20px !important;
        }
    }

    .mobile-view {
        display: none;
    }

    @media (min-width: 1920px) {
        .job-item {
            & img {
                width: 100% !important;
            }
        }
    }

    @media (max-width: 991px) {
        padding 16px !important;

        .job-item {
            width: 47%;

            & .job-item__col-noresult {
                margin: 0 !important;
                padding-left: 10px !important;
            }

            & .job-search__company {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500 !important;
            }
        }
    }
    
    @media (max-width: 767px) {
        padding 16px !important;

        .recommend-job-title {
            margin-top: 20px;
            margin-bottom: 20px;
    
            & h3 {
                display: block !important;
                display: -webkit-box !important;
            }
    
            & a {
                float: none;
                margin-left: 5px;
                font-size: 21px;
            }
        }

        .recommend-jobs-wrapper {
            gap: 5px;
        }

        .job-item {
            width: 100%;

            & .job-item__col-noresult {
                margin: 0 !important;
                padding-left: 10px !important;
            }

            & .job-search__company {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500 !important;
            }
        }

        .desktop-view {
            display: none;
        }

        .mobile-view {
            display: block;
            width: 100%;
            margin: 0 auto;
            background-color: #FFF;
            text-align: center;
            padding-top: 14px;
            padding-bottom: 14px;
            margin-top: 5px;
            border: 1px solid #EAEAF0;
            border-radius: 7px;
        }
    }    
`
