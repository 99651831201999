import React, { useContext } from 'react'
import AppGlobalContext from '../../packages/GlobalLayout/context'

export interface IWithGlobalTranslationProps {
    translate: (term: string) => string
}

/**
 * Found issues:
 * - This HOC makes wrapped component trigger render twice
 *
 * @todo
 * Giving more details about:
 * - Short description of what it's used for
 * - What different about this HOC from other similar translation HOC using in this project
 * - How to use
 * - Fix found issues above
 *
 * @param WrappedComponent
 *
 * @see https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example
 */

export function withGlobalTranslation<T extends IWithGlobalTranslationProps = IWithGlobalTranslationProps>(
    WrappedComponent: React.ComponentType<T>,
    resource: object
) {
    // Try to create a nice displayName for React Dev Tools.
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

    // Creating the inner component. The calculated Props type here is the where the magic happens.
    const ComponentWithTranslation = (props: Omit<T, keyof IWithGlobalTranslationProps>) => {
        // Fetch the props you want to inject. This could be done with context instead.
        const { langCode } = useContext(AppGlobalContext)

        const translate = (term: string) => {
            return resource[langCode]?.[term] || term
        }

        // props comes afterwards so the can override the default ones.
        return <WrappedComponent {...(props as T)} translate={translate} />
    }

    ComponentWithTranslation.displayName = `withGlobalTranslation(${displayName})`

    return ComponentWithTranslation
}
